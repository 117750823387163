/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Akce"} description={"Akce"}>
        <Column className="--menu css-1hlc7wc bg--center pb--08 pt--08" menu={true} name={"2lyb6zgupyh"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/12874/c4277610a53a4e679ffbffd74c449a88_bl=3_s=3000x_.png);
    }
  
    `}>
          
          <Menu className="--full" style={{"maxWidth":""}} fullscreen={true}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Subtitle className="subtitle-box fs--72 w--400 lh--1" content={"<span style=\"color: rgb(255, 255, 255);\">Aktuální akce</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2" href={"/"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Hlavní stránka</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2" href={"/dodavatele"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Dodavatelé</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2" href={"/galerie"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Galerie</span>"}>
                </Button>

                <Button className="btn-box btn-box--hvr4 btn-box--cbtn2 btn-box--sbtn2" href={"/pracovni-doba"} target={""} content={"<span style=\"color: rgb(255, 255, 255);\">Pracovní doba</span>"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="pb--60 pt--60" name={"hp1k6wnj5ad"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 --full flex--stretch" border={null} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"5ecvj5nnl7b"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image src={"https://cdn.swbpg.com/t/12874/462674d025e5435ba7368d1f3371912e_s=660x_.PNG"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":544}} srcSet={"https://cdn.swbpg.com/t/12874/462674d025e5435ba7368d1f3371912e_s=350x_.PNG 350w, https://cdn.swbpg.com/t/12874/462674d025e5435ba7368d1f3371912e_s=660x_.PNG 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Button className="btn-box" url={"https://www.floorforever.cz/wp-content/uploads/2023/10/FF-akce-letak-2023-237x330-2023-09-V2-online.pdf"} href={"https://www.floorforever.cz/wp-content/uploads/2023/10/FF-akce-letak-2023-237x330-2023-09-V2-online.pdf"} content={"Odkaz na akční nabídku"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2b3u2349gb1"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Image src={"https://cdn.swbpg.com/t/12874/123ce862af7148bb99fd7920e920d0b2_s=660x_.PNG"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":598}} srcSet={"https://cdn.swbpg.com/t/12874/123ce862af7148bb99fd7920e920d0b2_s=350x_.PNG 350w, https://cdn.swbpg.com/t/12874/123ce862af7148bb99fd7920e920d0b2_s=660x_.PNG 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sz4m206v0yc"} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}